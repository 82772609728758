import React, { ChangeEvent, useState, useEffect } from "react";
import ReactImageGallery from "react-image-gallery";

import './multiphotoupload.css'
interface PhotoUploadProps {
  onPhotosChange: (files: File[]) => void;
  onPhotoDelete: (photoUrl: string) => void;
  initialPhotos: string[];
}

const MultiPhotoUpload: React.FC<PhotoUploadProps> = ({
  onPhotosChange,
  onPhotoDelete,
  initialPhotos,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [files, setFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>(initialPhotos);

  useEffect(() => {
    setPreviews(initialPhotos);
  }, [initialPhotos]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files ? Array.from(event.target.files) : [];
    setFiles(newFiles);
    onPhotosChange(newFiles);
    updatePreviews(newFiles);
  };

  const updatePreviews = (newFiles: File[]) => {
    const newPreviews = newFiles.map((file) => URL.createObjectURL(file));
    setPreviews((prev) => [...prev, ...newPreviews]);
  };

  const handleDelete = (previewUrl: string) => {
    if (window.confirm("Are you sure you want to delete this photo?")) {
      setPreviews((prev) => prev.filter((p) => p !== previewUrl));
      onPhotoDelete(previewUrl);
    }
  };

  // Map previews to gallery format with delete button overlay
  const galleryItems = previews.map((previewUrl) => ({
    original: previewUrl,
    thumbnail: previewUrl,
    renderItem: () => (
      <div className="image-gallery-image">
        <img src={previewUrl} alt="preview" className="img-fluid rounded-5 "/>
        <button
          className="delete-button btn btn-danger"
          onClick={() => handleDelete(previewUrl)}
        >
          Delete
        </button>
      </div>
    ),
  }));

  return (
    <div>
      <input type="file" multiple onChange={handleFileChange} />
      <ReactImageGallery items={galleryItems} showThumbnails={true} />
    </div>
  );
};

export default MultiPhotoUpload;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Container,
  Form,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import { apiClient } from "../../../Utilities/apiClient";
import { SingleTrail, TrailDifficulty, TrailStatus } from "../../Data/Trails";
import {
  generateKML,
  parseKMLAndGetCoordinates,
} from "../../../Utilities/Helpers";
import * as _ from "lodash";
import IntervalInput from "./components/intervalInput";
import StartingPointInput from "./components/startingPointInput";
import "./Trail.css";
import MultiPhotoUpload from "./components/multiPhotoUpload";

const Trail: React.FC = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(id !== undefined);
  const [trail, setTrail] = useState<SingleTrail>({
      startingPoint: { coordinates: [0, 0], type: "Point" },
      photos: [],
  } as unknown as SingleTrail);
  const [originalTrail, setOriginalTrail] = useState<SingleTrail>();
  const [uploadedPhotos, setUploadedPhotos] = useState<File[]>([]);
  const [fileName, setFileName] = useState("");
  const [cities, setCities] = useState<any[]>([]);

  useEffect(() => {
    apiClient.get("/trails/cities").then((response) => {
      setCities(response.data.cities);
    });

    if (id === undefined) {
      setIsEditing(false);
      return;
    }

    apiClient
      .get(`/trails/${id}`)
      .then((response) => {
        setTrail(response.data.trail as SingleTrail);
        setOriginalTrail(response.data.trail as SingleTrail);
      })
      .catch((err) => {
        alert("Error loading trail data");
      });
  }, [id]);

 

  // Handle KML file upload
  function handleUpload() {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".kml";
    fileInput.onchange = (e: any) => {
      const file = e.target.files ? e.target.files[0] : null;
      if (file) {
        if (file.name.endsWith(".kml")) {
          setFileName(file.name);
          const reader = new FileReader();
          reader.onload = (event: ProgressEvent<FileReader>) => {
            const content = event.target?.result as string;
            const parsedCoords = parseKMLAndGetCoordinates(content);
            setTrail((prevTrail) => ({
              ...prevTrail,
              trailPath: {
                type: "LineString",
                coordinates: parsedCoords,
              },
            }));
          };
          reader.readAsText(file);
        } else {
          alert("Please upload a file with a .kml extension.");
        }
      }
    };
    fileInput.click();
  }

  function handleDownload() {
    if (!trail.trailPath || !trail.trailPath.coordinates.length) {
      alert("No trail path available to download.");
      return;
    }
    const kmlContent = generateKML(trail.trailPath.coordinates);
    const blob = new Blob([kmlContent], {
      type: "application/vnd.google-earth.kml+xml",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = trail.name ? `${trail.name}.kml` : "trail.kml";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  const setNewTrailValue = function <K extends keyof SingleTrail>(
    key: K,
    value: SingleTrail[K]
  ): void {
    setTrail((prevTrail) => ({
      ...prevTrail,
      [key]: value,
    }));
  };

  const handlePhotosChange = (files: File[]) => {
    setUploadedPhotos(files);
  };

  const handlePhotoDelete = (photoUrl: string) => {
      const photoName = photoUrl.split("/").pop(); 
  
      if (photoName) {
        apiClient
          .delete(`/trails/${trail.id}/photo/${photoName}`)
          .then((response) => {
            
            alert("Photo deleted successfully");
        setTrail((prevTrail) => ({
          ...prevTrail,
          photos: prevTrail.photos?.filter((photo) => photo !== photoUrl),
        }));
          })
          .catch((error) => {
            console.error("Error deleting photo:", error);
            alert("Failed to delete photo")
    
          });
      }
  };
  

  const updateTrail = () => {
    const delta = Object.keys(trail!).reduce((acc, key) => {
        if (trail![key] !== originalTrail![key]) {
            acc[key] = trail![key];
        }
        return acc;
    }, {} as SingleTrail);

    if (_.isEmpty(delta) && uploadedPhotos.length === 0 ) {
      alert("No changes detected.")

      return;
    }
    setLoading(true);
    if(delta.startingPoint) {
        const startingPointPutBody = {
            latitude: delta.startingPoint.coordinates[1],
            longitude: delta.startingPoint.coordinates[0]
        }
        apiClient.put(`/trails/starting-point/${originalTrail!.id}`, startingPointPutBody).then((response) => {
            setLoading(false);

            if(response.status === 200) {
                alert('Starting point updated successfully hold on while we update the remaining data');
            }
        });
        delete delta.startingPoint;
    }
    
    if(delta.trailPath) {
        const trailPathPutBody = {
            trail_path: delta.trailPath.coordinates
        }
        apiClient.put(`/trails/trail-path/${originalTrail!.id}`, trailPathPutBody).then((response) => {
            setLoading(false);

            if(response.status === 200) {
                alert('Trail path updated successfully hold on while we update the remaining data');
            }
        });
        delete delta.startingPoint;
    }
    if(delta.photos ||uploadedPhotos.length>0){
        const formData=new FormData()
        uploadedPhotos.forEach(file => {
            formData.append('photos', file, file.name);
        });
        apiClient.put(`/trails/${originalTrail!.id}`, formData,{

        contentType: 'multipart/form-data'
    
        }).then((response) => {
          alert("Trail photos updated successfully hold on while we update the remaining data");
          setUploadedPhotos([]); 
          setLoading(false)

          return apiClient
          .get(`/trails/${id}`)
          .then((response) => {
            setTrail(response.data.trail as SingleTrail);
            setOriginalTrail(response.data.trail as SingleTrail);
          })
          .catch((err) => {
            alert("Error loading trail data");

            console.error(err);
          });      
        });
        delete delta.photos;
     
    }
    // eslint-disable-next-line no-restricted-globals
    if(_.isEmpty(delta))  return
    setLoading(true); 
    if (delta.cityId) {
        delta.cityId = Number(delta.cityId);
    }
    apiClient.put(`/trails/${originalTrail!.id}`, delta).then((response) => {
        if(response.status === 200) {
            alert('Trail updated successfully');
        }
        apiClient.get(`/trails/${id}`).then((response) => {
            setUploadedPhotos([]); // Clear new photos list
            // Reload trail data to refresh display
    
            setTrail(response.data.trail as SingleTrail);
            setOriginalTrail(response.data.trail as SingleTrail);
            setLoading(false);
            return apiClient.get(`/trails/${id}`);

        })
    }).catch((error) => {
        console.error('Error updating trail:', error);
        alert("Error updating trail");
        

        setLoading(false);
    });
  }
    

  const createTrail = () => {
    if (!trail.cityId) {
      alert("Please fill in the city field");
      return;
    }
    if (!trail.name) {
      alert("Please fill in the name field");
      return;
    }
    if (!trail.distance) {
      alert("Please fill in the distance field");
      return;
    }
    if (!trail.difficulty) {
      alert("Please fill in the difficulty field");
      return;
    }
    if (!trail.time) {
      alert("Please fill in the time field");
      return;
    }
    if (trail.startingPoint?.coordinates.length !== 2) {
      alert("Please fill in the starting point field");
      return;
    }
    if (!trail.recommendedGear) {
      alert("Please fill in the recommended gear field");
      return;
    }
    if (!trail.notes) {
      alert("Please fill in the notes field");
      return;
    }
    if (!trail.description) {
      alert("Please fill in the description field");
      return;
    }
    if (!trail.trailPath) {
      alert("Please fill in the trail path field");
      return;
    }
    if (!uploadedPhotos.length) {
      alert("Please upload at least one photo");
      return;
    }
    if (trail.isTwoWay === undefined) {
      alert("Please fill in the is two way field");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("name", trail.name);
    formData.append("distance", trail.distance.toString());
    formData.append("difficulty", trail.difficulty);
    formData.append("time", `${trail.time}`);
    formData.append(
      "longitude",
      trail.startingPoint.coordinates[0].toString()
    );
    formData.append(
      "latitude",
      trail.startingPoint.coordinates[1].toString()
    );
    formData.append("recommendedGear", trail.recommendedGear);
    formData.append("notes", trail.notes);
    formData.append("description", trail.description);

    // Transform coordinates for trail path
    const coords = trail.trailPath.coordinates
      .map((coord) => coord.join(" "))
      .join(", ");
    formData.append("trail_path", `[${coords}]`);
    formData.append("cityId", trail.cityId as unknown as string);
    uploadedPhotos.forEach((file) => {
      formData.append("photos", file, file.name);
    });
    formData.append("isTwoWay", trail.isTwoWay.toString());

    apiClient
      .post(`/trails`, formData, {
        contentType: "multipart/form-data"
    })
      .then(() => {
        alert("Trail added successfully");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error creating trail:", error);
        alert("Error creating trail");
        setLoading(false);
      });
  };

  return (
    <Container className="single-element-container">
        
      <div>
        <div className="photos-element-container">
         
          <MultiPhotoUpload
            onPhotosChange={handlePhotosChange}
            onPhotoDelete={handlePhotoDelete}
            initialPhotos={trail.photos || []}
          />
        </div>
        <div className="element-meta-data">
          {isEditing && (
            <>
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Text
                  id="inputGroup-sizing-sm"
                  className="meta-element-label"
                >
                  ID
                </InputGroup.Text>
                <Form.Control
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  value={trail?.id}
                  disabled
                />
              </InputGroup>
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Text
                  id="inputGroup-sizing-sm"
                  className="meta-element-label"
                >
                  Created At
                </InputGroup.Text>
                <Form.Control
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  value={trail?.createdAt as unknown as string}
                  disabled
                />
              </InputGroup>
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Text
                  id="inputGroup-sizing-sm"
                  className="meta-element-label"
                >
                  Rating
                </InputGroup.Text>
                <Form.Control
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  value={trail?.rating}
                  disabled
                />
              </InputGroup>
            </>
          )}
          { (
            <>
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Text
                  id="inputGroup-sizing-sm"
                  className="meta-element-label"
                >
                  City
                </InputGroup.Text>
                <Form.Select
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  onChange={(e) =>
                    setNewTrailValue(
                      "cityId",
                      e.target.value as unknown as number
                    )
                  }
                  value={trail?.cityId}
                >
                  {cities.map((city: any) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
            </>
          )}
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text
              id="inputGroup-sizing-sm"
              className="meta-element-label"
            >
              Trail Path
            </InputGroup.Text>
            <InputGroup.Text
              id="inputGroup-sizing-sm"
              className="meta-element-label"
            >
              .kml file only
            </InputGroup.Text>
            <Form.Control
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              placeholder="No file chosen"
              value={fileName}
              disabled
            />
            <Button variant="outline-secondary" onClick={handleUpload}>
              Upload
            </Button>
            <Button variant="outline-secondary" onClick={handleDownload}>
              Download
            </Button>
            
          
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text
              id="inputGroup-sizing-sm"
              className="meta-element-label"
            >
              Trail Name
            </InputGroup.Text>
            <Form.Control
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              onChange={(e) => setNewTrailValue("name", e.target.value)}
              value={trail?.name || ""}
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text
              id="inputGroup-sizing-sm"
              className="meta-element-label"
            >
              Distance
            </InputGroup.Text>
            <Form.Control
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              onChange={(e) =>
                setNewTrailValue("distance", parseFloat(e.target.value))
              }
              type="number"
              step="any"
              value={trail?.distance || ""}
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text
              id="inputGroup-sizing-sm"
              className="meta-element-label"
            >
              Difficulty
            </InputGroup.Text>
            <Form.Select
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              onChange={(e) =>
                setNewTrailValue(
                  "difficulty",
                  e.target.value as TrailDifficulty
                )
              }
              value={trail?.difficulty || ""}
            >
              {Object.values(TrailDifficulty).map((difficulty) => (
                <option key={difficulty} value={difficulty}>
                  {difficulty}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <IntervalInput
              value={isEditing ?originalTrail?.time :trail?.time || { hours: 0, minutes: 0 }}
              field="time"
              onChange={setNewTrailValue}
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <StartingPointInput
              coordinates={trail?.startingPoint!.coordinates}
              field="startingPoint"
              onChange={(field, coordinates) =>
                setNewTrailValue(field as keyof SingleTrail, {
                  type: "Point",
                  coordinates,
                } as SingleTrail["startingPoint"])
              }
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text
              id="inputGroup-sizing-sm"
              className="meta-element-label"
            >
              Recommended Gear
            </InputGroup.Text>
            <Form.Control
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              onChange={(e) =>
                setNewTrailValue("recommendedGear", e.target.value)
              }
              value={trail?.recommendedGear || ""}
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text
              id="inputGroup-sizing-sm"
              className="meta-element-label"
            >
              Notes
            </InputGroup.Text>
            <Form.Control
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              onChange={(e) => setNewTrailValue("notes", e.target.value)}
              value={trail?.notes || ""}
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text
              id="inputGroup-sizing-sm"
              className="meta-element-label"
            >
              Description
            </InputGroup.Text>
            <Form.Control
              as="textarea"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              onChange={(e) =>
                setNewTrailValue("description", e.target.value)
              }
              value={trail?.description || ""}
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text
              id="inputGroup-sizing-sm"
              className="meta-element-label"
            >
              Is Two Way
            </InputGroup.Text>
            <Form.Check
              type="checkbox"
              aria-label="checkbox"
              onChange={(e) =>
                setNewTrailValue("isTwoWay", e.target.checked)
              }
              checked={trail?.isTwoWay || false}
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text
              id="inputGroup-sizing-sm"
              className="meta-element-label"
            >
              Is Recommended
            </InputGroup.Text>
            <Form.Check
              type="checkbox"
              aria-label="checkbox"
              onChange={(e) =>
                setNewTrailValue("isRecommended", e.target.checked)
              }
              checked={trail?.isRecommended || false}
            />
          </InputGroup>
          {isEditing && (
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text
                id="inputGroup-sizing-sm"
                className="meta-element-label"
              >
                Status
              </InputGroup.Text>
              <Form.Select
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                onChange={(e) =>
                  setNewTrailValue("status", e.target.value as TrailStatus)
                }
                value={trail?.status || ""}
              >
                {Object.values(TrailStatus).map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
          )}

          <div className="trail-buttons">
            {isEditing && (
              <Button variant="success" onClick={updateTrail} disabled={loading}>
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Update"
                )}
              </Button>
            )}
            {!isEditing && (
              <Button
                variant="success"
                onClick={createTrail}
                disabled={loading}
              >
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Create"
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
      <Modal show={loading} backdrop="static" keyboard={false} centered>
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" />
          <p className="mt-3">Processing your request, please wait...</p>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Trail;
